import {useEffect, useState} from 'react';
import {useOutlet} from 'reconnect.js';

export const useNewsList = () => {
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [actions] = useOutlet('actions');

  useEffect(() => {
    const fetchNewsList = async () => {
      try {
        setIsLoading(true);
        const resp = await actions.fetchNewsList();
        setNewsList(resp);
      } catch (e) {
        console.log('debug', e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchNewsList().then(() => 0);
  }, [actions]);

  return {
    isLoading,
    newsList,
  };
};
