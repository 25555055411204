import React from 'react';
import styled from 'styled-components';
import {useNewsList} from '../../Hooks/useNewsList';
import Spinner from '../../Components/Spinner';
import {Card} from '../../widget';
import moment from 'moment';
import {navigate} from 'gatsby-link';
import {Breakpoints, Color} from '../../constants';

const NewsList = (props) => {
  const {newsList, isLoading} = useNewsList();

  if (isLoading) {
    return <Spinner />;
  }
  console.log('debug', newsList);
  return (
    <Wrapper>
      {newsList.map((n, i) => (
        <NewsItem
          key={i}
          onClick={async () => {
            await navigate(`/news?id=${n.id}`);
          }}>
          <img src={n.image} alt={`${n.title}-${i}`} />
          <div className="context">
            <div className="title">{n.title}</div>
            <div className="outline">{n.outline}</div>
            <div className="date">
              {moment(new Date(n.created)).format('YYYY-MM-DD')}
            </div>
          </div>
        </NewsItem>
      ))}
    </Wrapper>
  );
};

const NewsItem = styled(Card)`
  width: 100%;
  margin-bottom: 10px;
  padding: 0;
  & > img {
    width: calc(100%);
    aspect-ratio: calc(998 / 552);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
  }
  & > .context {
    padding: 15px;
    text-align: left;
    & > .title {
      font-size: 22px;
    }
    & > .outline {
      font-size: 16px;
    }
    & > .data {
      font-size: 12px;
      color: ${Color.black_30};
    }
  }
`;

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
`;

export default NewsList;
